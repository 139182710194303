import { React, useEffect } from "react";
import { motion } from "framer-motion";

const TermsOfServicePage = () => {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 1);
    }, []);
    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 py-10">
            <motion.div
                initial={{ opacity: 0, translateY: "100%" }}
                animate={{ opacity: 1, translateY: 0 }}
                className="max-w-2xl px-4 py-6 bg-white shadow-lg rounded-lg"
            >
                <h1 className="text-3xl font-bold mb-4 text-gray-800">Terms of Service</h1>
                <p className="text-gray-700 mb-4">
                    Welcome to Uzmoon. By accessing our website, you agree to comply with and be bound by the following terms and conditions of use, which together with our Privacy Policy govern Uzmoon's relationship with you.
                </p>
                <p className="text-gray-700 mb-4">
                    The term 'Uzmoon' or 'us' or 'we' refers to the owner of the website. The term 'you' refers to the user or viewer of our website.
                </p>
                <p className="text-gray-700 mb-4">
                    The use of this website is subject to the following terms of service:
                </p>
                <ol className="list-decimal text-gray-700 pl-8 mb-4">
                    <li>You may not use our website for any illegal or unauthorized purpose.</li>
                    <li>You agree to provide accurate and complete information when using our website.</li>
                    <li>Your use of any information or materials on this website is entirely at your own risk.</li>
                    <li>This website may contain links to other websites which are not under the control of Uzmoon. We have no control over the nature, content, and availability of those sites.</li>
                    <li>Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offense.</li>
                </ol>
                <p className="text-gray-700 mb-4">
                    By using our website, you agree to these terms of service. If you disagree with any part of these terms, please do not use our website.
                </p>
            </motion.div>
        </div>
    );
};

export default TermsOfServicePage;
