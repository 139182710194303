import React, { useState, useEffect, useRef, useCallback } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import { VscDebugAlt } from "react-icons/vsc";
import { motion } from 'framer-motion';
import { codeSnippets } from "../assets/js/code_snippets";
import { animateScroll as scroll } from 'react-scroll';


const HelloWorlds = () => {
  const [visibleSnippets, setVisibleSnippets] = useState(12);
  const sentinelRef = useRef(null);
  const [typingCode, setTypingCode] = useState({});

  const loadMoreSnippets = useCallback(() => {
    if (visibleSnippets < codeSnippets.length) {
      setVisibleSnippets((prev) => prev + 12);
    }
  }, [visibleSnippets]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMoreSnippets();
        }
      },
      { threshold: 0.1 }
    );
    const currentSentinel = sentinelRef.current;

    if (currentSentinel) {
      observer.observe(currentSentinel);
    }

    return () => {
      if (currentSentinel) {
        observer.unobserve(currentSentinel);
      }
    };
  }, [loadMoreSnippets]);

  useEffect(() => {
    setTimeout(() => {
      scroll.scrollToTop({ duration: 1000, smooth: 'easeInOutQuad' });
    }, 100);
  }, []);

  const handleRunDebug = (snippet, index) => {
    console.log("Hello, World!");

    const typeCode = (code) => {
      let i = 0;
      setTypingCode((prev) => ({ ...prev, [index]: "" }));
      const interval = setInterval(() => {
        setTypingCode((prev) => ({ ...prev, [index]: code.slice(0, i + 1) }));
        i++;
        if (i === code.length) clearInterval(interval);
      }, 20);
    };

    typeCode(snippet.code);
  };

  return (
    <div className="bg-slate-900">
      <motion.div
        initial={{ opacity: 0, translateY: "-100%" }}
        animate={{ opacity: 1, translateY: 0 }}
        className="flex flex-col items-center justify-center"
      >
        <div className="min-h-screen text-white py-10 md:w-[75%]">
          <h1 className="text-4xl font-bold mb-8 text-center">1 Line 1 Life</h1>
          <div className="mx-5">
            <p className="text-lg font-light mb-4 text-center">There are thousands of ways to say 'Hello, World', but each line can impact a life. As we write code, we're building a world, creating transformation with the power of a single line.</p>
            <p className="text-lg font-light mb-8 text-center">We came to update the world.</p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-5 mx-10">
            {codeSnippets.slice(0, visibleSnippets).map((snippet, index) => (
              <div key={index} className="relative border border-gray-700 rounded-md" data-aos="fade-up">
                <div className="bg-gray-700 w-full rounded-t-md p-4">
                  <div className="absolute top-2 left-3 flex items-center mb-5">
                    <div className="w-3 mr-1 h-3 bg-red-500 rounded-full"></div>
                    <div className="w-3 mr-1 h-3 bg-yellow-500 rounded-full"></div>
                    <div className="w-3 mr-1 h-3 bg-green-500 rounded-full"></div>
                  </div>
                  <div className="absolute top-1 right-3">
                    <VscDebugAlt onClick={() => handleRunDebug(snippet, index)} className="text-green-400 cursor-pointer" size={24} />
                  </div>
                </div>
                <div className="flex justify-between items-center mb-2 ml-6 mt-5">
                  <h2 className="text-xl font-semibold">{snippet.language}</h2>
                </div>
                <div className="overflow-hidden rounded-md">
                  <SyntaxHighlighter language={snippet.language_code} style={vscDarkPlus} wrapLines showLineNumbers lineProps={{ style: { padding: '0 10px', display: 'table', width: '100%' } }} className="bg-transparent">
                    {typingCode[index] !== undefined ? typingCode[index] : snippet.code}
                  </SyntaxHighlighter>
                </div>
              </div>
            ))}
            <span ref={sentinelRef} className="col-span-full w-full h-1"></span>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default HelloWorlds;
