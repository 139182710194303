import { React, useEffect } from "react";
import { motion } from "framer-motion";

const logos = [
    {
        type: "logo",
        name: "Uzmoon Logo Black",
        download: "uzmoon_black_180x180",
        size: "180x180",
        png: "./assets/images/logos/uzmoon_black_180x180.png",
        svg: "./assets/images/logos/uzmoon_black_180x180.svg",
        color: "black",
    },
    {
        type: "logo",
        name: "Uzmoon Logo Black",
        download: "uzmoon_black_300x300",
        size: "300x300",
        png: "./assets/images/logos/uzmoon_black_300x300.png",
        svg: "./assets/images/logos/uzmoon_black_300x300.svg",
        color: "black",
    },
    {
        type: "logo",
        name: "Uzmoon Logo Black",
        download: "uzmoon_black_320x320",
        size: "320x320",
        png: "./assets/images/logos/uzmoon_black_320x320.png",
        svg: "./assets/images/logos/uzmoon_black_320x320.svg",
        color: "black",
    },
    {
        type: "logo",
        name: "Uzmoon Logo Black",
        download: "uzmoon_black_400x400",
        size: "400x400",
        png: "./assets/images/logos/uzmoon_black_400x400.png",
        svg: "./assets/images/logos/uzmoon_black_400x400.svg",
        color: "black",
    },
    {
        type: "logo",
        name: "Uzmoon Logo White",
        download: "uzmoon_white_180x180",
        size: "180x180",
        png: "./assets/images/logos/uzmoon_white_180x180.png",
        svg: "./assets/images/logos/uzmoon_white_180x180.svg",
        color: "white",
    },
    {
        type: "logo",
        name: "Uzmoon Logo White",
        download: "uzmoon_white_300x300",
        size: "300x300",
        png: "./assets/images/logos/uzmoon_white_300x300.png",
        svg: "./assets/images/logos/uzmoon_white_300x300.svg",
        color: "white",
    },
    {
        type: "logo",
        name: "Uzmoon Logo White",
        download: "uzmoon_white_320x320",
        size: "320x320",
        png: "./assets/images/logos/uzmoon_white_320x320.png",
        svg: "./assets/images/logos/uzmoon_white_320x320.svg",
        color: "white",
    },
    {
        type: "logo",
        name: "Uzmoon Logo White",
        download: "uzmoon_white_400x400",
        size: "400x400",
        png: "./assets/images/logos/uzmoon_white_400x400.png",
        svg: "./assets/images/logos/uzmoon_white_400x400.svg",
        color: "white",
    },
    {
        type: "icon",
        name: "Uzmoon Black Icon",
        download: "uzmoon_icon_black_512x512",
        size: "512x512",
        png: "./assets/images/logos/uzmoon_icon_black_512x512.png",
        svg: "./assets/images/logos/uzmoon_icon_black_512x512.svg",
        color: "black",
    },
    {
        type: "icon",
        name: "Uzmoon White Icon",
        download: "uzmoon_icon_white_512x512",
        size: "512x512",
        png: "./assets/images/logos/uzmoon_icon_white_512x512.png",
        svg: "./assets/images/logos/uzmoon_icon_white_512x512.svg",
        color: "white",
    },
];

const LogosPage = () => {
    useEffect(() => {

        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 100);
    }, []);

    const groupedLogos = logos.reduce((acc, logo) => {
        if (!acc[logo.type]) {
            acc[logo.type] = [];
        }
        acc[logo.type].push(logo);
        return acc;
    }, {});

    return (
        <div className="px-10 bg-gray-100">
            <motion.div
                initial={{ opacity: 0, translateY: "100%" }}
                animate={{ opacity: 1, translateY: 0 }}
                className="min-h-screen  flex flex-col items-center  py-10"
            >
                <h1 className="text-4xl font-bold my-8 text-gray-800">Logos and Icons</h1>

                {Object.keys(groupedLogos).map((type, typeIndex) => (
                    <div key={typeIndex} className="w-full max-w-7xl mb-12">
                        <h2 className="text-3xl font-semibold mb-6 text-gray-700 capitalize">{type}s</h2>
                        <div className="logos-grid grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                            {groupedLogos[type].map((logo, index) => (
                                <div
                                    key={index}
                                    className={`${logo.color === 'white' ? 'bg-black' : 'bg-white'} logo-card flex flex-col items-center p-6 rounded-lg shadow-md transform transition-transform hover:scale-105 `}
                                    data-aos="fade-up"
                                >
                                    <h3 className="text-xl font-semibold mb-2 text-gray-700">{logo.name}</h3>
                                    <img src={logo.png} alt={`${logo.name} PNG`} className="mb-4 w-32 h-32 object-contain" />
                                    <p className="text-sm text-gray-500 mb-4">Size: {logo.size}</p>
                                    <div className="flex space-x-4">
                                        <a href={logo.png} download={`${logo.download}.png`} className="text-blue-500 hover:underline">
                                            Download PNG
                                        </a>
                                        <a href={logo.svg} download={`${logo.download}.svg`} className="text-blue-500 hover:underline">
                                            Download SVG
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))
                }
            </motion.div >
        </div>
    );
};

export default LogosPage;
