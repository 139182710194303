import { services } from "../../assets/js/services";
import Veba from "./veba";

const Services = () => {
  return (
    <div className="container mx-auto my-32 q" id="services">
      <div className="flex flex-col items-center justify-center">
        <p className="title">Our Services</p>
        <div className="h-1 w-48 mb-6" style={{ background: 'black' }}></div>
        <div className="w-full">
          <div className="mb-8">
            <div className="flex w-full items-center justify-center">
              <Veba />
            </div>
          </div>
          <div className="block ">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-center justify-center text-center mx-auto">
              {services.map((service, index) => {
                return (
                  <div
                    key={index}
                    className="service-card_ae mt-3 my-image-class col-span-3 md:col-span-1 card px-4 py-8 md:mx-10 transition duration-300 ease-in-out"
                    id={'services-' + index}
                  >

                    <div className="service-card" >
                      {service.name[0]}
                      <br />
                      {service.name[1]}
                    </div>

                    {service.services.map((item, index) => (
                      <p key={index} className="service-card-item">
                        {item}
                      </p>
                    ))}
                  </div>
                );
              })}
            </div>
          </div>

          {/* <div className="md:hidden">
            <div className="" infiniteLoop={true} showStatus={false}>
              {services.map((service, index) => {
                return (
                  <div className="card col-span-3 p-8 mb-10 gap-x-3" key={index}>
                    <div className="service-card ">{service.name}</div>
                    {service.services.map((item, index) => (
                      <p key={index} className="service-card-item ">
                        {item}
                      </p>
                    ))}
                  </div>
                );
              })}
            </div>
          </div> */}
        </div>
      </div>

    </div>
  );
};

export default Services;
