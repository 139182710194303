import React from "react";
import Hero from "../components/home/hero";
import About from "../components/home/about";
import Mka from "../components/home/mka";
import Services from "../components/home/services";
import Projects from "../components/home/projects";
import Contact from "../components/home/contact";
import ParallaxText from "../components/home/paralax";

const LandingPage = () => {
  return (
    <>
      <Hero />
      <ParallaxText baseVelocity={-3}>WE ARE UZMOON</ParallaxText>
      <ParallaxText baseVelocity={2}>WE CAME TO UPDATE THE WORLD</ParallaxText>
      <About />
      <Services />
      <Mka />
      <Projects />
      <Contact />
    </>
  );
};

export default LandingPage;
