import { React, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import LandingPage from './pages/landing';
import LineLife from './pages/line_life';
import ProjectsPage from './pages/projects';
import Headers from './components/header';
import Footer from './components/footer';
import LogosPage from './pages/logos';
import PrivacyPolicyPage from './pages/privacy_policy';
import TermsOfServicePage from './pages/terms_of_service';
import Aos from 'aos';
import 'aos/dist/aos.css';

function App() {
    const location = useLocation();
     
    useEffect(() => {
        const hash = location.hash.replace('#', '');
        if (hash) {
            const element = document.getElementById(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }else{
            if(location.pathname === '/' && hash === ''){
                const element = document.getElementById('root');
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }
        Aos.init({ duration: 700 });

    }, [location]);



    return (
        <>
            <Headers />
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/1ine1ife" element={<LineLife />} />
                <Route path="/projects" element={<ProjectsPage />} />
                <Route path="/logos" element={<LogosPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                <Route path="/terms-of-service" element={<TermsOfServicePage />} />
            </Routes>
            <Footer />
        </>
    )
}
export default App;

