import React, { useEffect, useRef } from "react";
import * as THREE from "three";

const Veba = () => {
  const canvasRef = useRef(null);
  const rendererRef = useRef(null);
  const sceneRef = useRef(null);
  const cameraRef = useRef(null);
  const meshRef = useRef(null);
  const geometryRef = useRef(null);
  const timeRef = useRef(0);

  const wireframe = true;

  const createIndexedPlaneGeometry = (width, length) => {
    const geom = new THREE.BufferGeometry();
    const vertices = [];
    const indices = [];
    const uvs = [];
    const width1 = width + 1;
    const length1 = length + 1;
    for (let i = 0; i < width1; i++) {
      for (let j = 0; j < length1; j++) {
        vertices.push(i / width, 0, j / length);
        uvs.push(i / width, j / length);
      }
    }

    for (let x = 0; x < width; x++) {
      for (let z = 0; z < length; z++) {
        const a = x * length1 + z;
        const b = x * length1 + z + 1;
        const c = (x + 1) * length1 + z;
        const d = (x + 1) * length1 + z + 1;

        indices.push(a, c, b);
        indices.push(b, c, d);
      }
    }

    const positions = new Float32Array(vertices);
    const index = new Uint32Array(indices);
    const uv = new Float32Array(uvs);

    geom.setAttribute("position", new THREE.BufferAttribute(positions, 3));
    geom.setAttribute("uv", new THREE.BufferAttribute(uv, 2));
    geom.setIndex(new THREE.BufferAttribute(index, 1));
    return geom;
  };

  const main = (geom, radius) => {
    const pos = geom.attributes.position.array;
    const uvs = geom.attributes.uv.array;
    const pi = Math.PI;

    for (let i = 0, u = 0, v = 1; i < pos.length; i += 3, u += 2, v += 2) {
      pos[i] = radius * Math.sin(uvs[u] * pi) * Math.cos(uvs[v] * 2 * pi);
      pos[i + 1] = radius * Math.sin(uvs[u] * pi) * Math.sin(uvs[v] * 2 * pi);
      pos[i + 2] = radius * Math.cos(uvs[u] * pi);
    }

    geom.setAttribute("base_position", geom.attributes.position.clone());
    geom.computeVertexNormals();
  };

  const initScene = (canvas) => {
    const scene = new THREE.Scene();
    const renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: false,
    });
    const isMobile = window.innerWidth < 768;
    renderer.setClearColor(0xffffff, 1.0);
    renderer.autoClearColor = false;
    renderer.setSize(
      window.innerWidth / (isMobile ? 1 : 2),
      window.innerHeight / (isMobile ? 2 : 2)
    );
    canvas.appendChild(renderer.domElement);

    const light = new THREE.PointLight(0x353535, 1, 0);
    light.position.set(0, 400, 400);
    scene.add(light);

    const camera = new THREE.PerspectiveCamera(
      isMobile ? 30 : 30,
      window.innerWidth * (isMobile ? 2 : 1) / window.innerHeight,
      0.1,
      1000
    );
    camera.position.set(0, 0, 550);

    return { scene, renderer, camera };
  };

  const rotateObject = (object, degreeX = 0, degreeY = 0, degreeZ = 0) => {
    degreeX = (degreeX * Math.PI) / 180;
    degreeY = (degreeY * Math.PI) / 180;
    degreeZ = (degreeZ * Math.PI) / 180;

    object.rotateX(degreeX);
    object.rotateY(degreeY);
    object.rotateZ(degreeZ);
  };

  const init = (canvas) => {
    const { scene, renderer, camera } = initScene(canvas);

    const mesh = new THREE.Object3D();
    mesh.scale.set(100, 100, 100);
    rotateObject(mesh, -90, -45, -180);

    const geometry = createIndexedPlaneGeometry(50, 75);
    main(geometry, 1);

    const material = new THREE.MeshStandardMaterial({
      color: 0x123524,
      emissive: 0x353535,
      metalness: 0.5,
      wireframe: wireframe,
    });

    const meshGeometry = new THREE.Mesh(geometry, material);
    mesh.add(meshGeometry);
    scene.add(mesh);

    rendererRef.current = renderer;
    sceneRef.current = scene;
    cameraRef.current = camera;
    meshRef.current = mesh;
    geometryRef.current = geometry;

    animate();
  };

  const modifyGeometry = () => {
    const geometry = geometryRef.current;
    if (!geometry) return;

    const pos = geometry.attributes.position.array;
    const base_pos = geometry.attributes.base_position.array;
    const uvs = geometry.attributes.uv.array;
    const time = timeRef.current;

    for (let i = 0, j = 0; i < pos.length; i += 3, j += 2) {
      let scale = 0.01 * Math.cos(uvs[j] * 7 + time * 0.01);
      scale += 0.05 * Math.cos(uvs[j + 1] * 9 + time * 0.05);

      for (let k = 2; k < 6; k += 2) {
        scale += 0.05 * k * Math.cos(uvs[j] * 9 * k + (k + time * 0.05));
        scale += 0.05 * k * Math.cos(uvs[j + 1] * 7 * k + (k + time * 0.05));
      }

      scale *= scale * 0.7 * Math.sin(time * 0.04 + uvs[j] * 4);

      pos[i] = base_pos[i] * (1 + scale);
      pos[i + 1] = base_pos[i + 1] * (1 + scale);
      pos[i + 2] = base_pos[i + 2] * (1 + scale);
    }

    geometry.attributes.position.needsUpdate = true;
    geometry.computeVertexNormals();
  };

  const animate = () => {
    timeRef.current++;
    requestAnimationFrame(animate);
    modifyGeometry();
    if (rendererRef.current && sceneRef.current && cameraRef.current) {
      rendererRef.current.render(sceneRef.current, cameraRef.current);
    }
  };

  useEffect(() => {
    var CanvaRef = canvasRef.current;
    const canvas = CanvaRef;
    if (canvas && !sceneRef.current) {
      init(canvas);
    }

    return () => {
      if (rendererRef.current) {
        rendererRef.current.dispose();
        if (canvas) {
          canvas.innerHTML = "";
        }
      }
      if (sceneRef.current) {
        sceneRef.current.children.forEach((child) => {
          if (child.geometry) child.geometry.dispose();
          if (child.material) child.material.dispose();
        });
      }
      sceneRef.current = null;
      rendererRef.current = null;
      cameraRef.current = null;
      meshRef.current = null;
      geometryRef.current = null;
    };
  }, [canvasRef]); // eslint-disable-line react-hooks/exhaustive-deps

  return <div ref={canvasRef} id="canvas" className="service-canvas" />;
};

export default Veba;
