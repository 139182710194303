import { React } from 'react';
import { projects } from "../../assets/js/projects";
import ProjectCard from "./project_card";
import { Link } from 'react-router-dom';

const Projects = () => {

  return (
    <div className="py-24" id="projects">
      <div className="container md:px-6 flex flex-col items-center">
        <h3 className="text-4xl font-bold text-uzmoon-500 mb-6 font-['Inter'] title">Projects</h3>
        <div className="h-1 w-48 mb-6" style={{ background: 'black' }}></div>
        <div className='md:w-[70%] grid md:grid-cols-2 lg:grid-cols-3 items-center justify-between gap-x-5 gap-y-5'>
          {projects.slice(0, 6).map((project, index) => (
            <ProjectCard
              key={index}
              index={index}
              name={project.name}
              logo={project.logo}
              photo={project.photo}
              description={project.description}
              langs={project.langs}
              style={index % 2}
            />
          ))}
        </div>
        <div className='mt-5'>
          <Link to='/projects' className="btn uzmoon-btn text-uzmoon-500 font-bold text-lg hover:text-uzmoon-600 transition duration-300 ease-in-out">
            View All Projects
          </Link>
        </div>
      </div>
    </div>

  );
};

export default Projects;
