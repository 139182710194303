import React from 'react';

const About = () => {

  return (
    <div className="my-24" id="about">
      <div className="container px-6 flex flex-col items-center">
        <h3 className="text-4xl font-bold text-uzmoon-500 mb-6 font-['Inter'] title">About Us</h3>
        <div className="h-1 w-48 mb-6" style={{ background: 'black' }}></div>
        <p className="font-['SpaceGrotesk'] text-lg text-gray-800 text-center leading-relaxed mx-auto max-w-[790px] text-center text-base text-[#4D5761] md:text-xl md:line-h">
          We are a team of passionate developers exploring the depths of the universe
          through our code. Our mission is to bring the wonders of space closer to you
          through innovative web applications. From the mysterious black holes to the
          distant galaxies, our creations will take you on a journey through the cosmos.
        </p>
        <p className="text-lg text-gray-800 text-center mt-4">
          Join us in this cosmic adventure and let your imagination soar among the stars!
        </p>
      </div>
    </div>
  );
};

export default About;
