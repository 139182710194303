import React, { useEffect } from "react";
import { motion } from "framer-motion";

const PrivacyPolicyPage = () => {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 100);
    }, []);

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 py-10">
            <motion.div
                initial={{ opacity: 0, translateY: "100%" }}
                animate={{ opacity: 1, translateY: 0 }}
                className="max-w-3xl px-4 py-6 bg-white shadow-lg rounded-lg"
            >
                <h1 className="text-3xl font-bold mb-4 text-gray-800">Privacy Policy</h1>
                <p className="text-gray-700 mb-4">
                    This privacy policy describes how Uzmoon collects, uses, protects, and discloses personal data collected on our website.
                </p>
                <h2 className="text-xl font-bold mb-2 text-gray-800">Google Analytics</h2>
                <p className="text-gray-700 mb-4">
                    Our website may use third-party analytics services, such as Google Analytics, to track visitor interactions and analyze website performance.
                    These services may collect information using cookies and generate reports on website usage. For more information, please refer to the <a href="https://policies.google.com/privacy" className="text-blue-500 hover:underline">Google Analytics Privacy Policy</a>.
                </p>
                <h2 className="text-xl font-bold mb-2 text-gray-800">Use of Personal Data</h2>
                <p className="text-gray-700 mb-4">
                    Uzmoon may use the collected personal data for the following purposes:
                    <ul className="list-disc list-inside">
                        <li>To provide and maintain our website;</li>
                        <li>To notify you about changes to our website;</li>
                        <li>To allow you to participate in interactive features of our website when you choose to do so;</li>
                        <li>To provide customer support;</li>
                        <li>To gather analysis or valuable information so that we can improve our website;</li>
                        <li>To monitor the usage of our website;</li>
                        <li>To detect, prevent and address technical issues;</li>
                        <li>To provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information;</li>
                    </ul>
                </p>
                <h2 className="text-xl font-bold mb-2 text-gray-800">Privacy Rights</h2>
                <p className="text-gray-700 mb-4">
                    If you have any questions or requests regarding your personal data, please contact us. We will respond to your request to update, correct or delete your personal data.
                </p>
                <h2 className="text-xl font-bold mb-2 text-gray-800">Policy Changes</h2>
                <p className="text-gray-700 mb-4">
                    This privacy policy may be updated or changed at any time. Changes will be posted on our website and will become effective when posted.
                </p>
            </motion.div>
        </div>
    );
};

export default PrivacyPolicyPage;
