import React from "react";
import logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";
import { AiOutlineInstagram, AiOutlineMail, AiFillGithub, AiFillLinkedin, AiOutlineTwitter } from 'react-icons/ai';


const Footer = () => {
  return (
    <div className="border-t-2 border-indigo-500  pt-10"

    >

      <div className="container font-['Inter'] ">
        <div className="mx-auto">
          <div className="grid grid-cols-4 pb-8">

            <div className="col-span-4 pb-6 md:col-span-2">
              <div className="flex max-w-[460px] flex-col ">
                <div className="col-span-4 flex items-center">
                  <img
                    className="my-5 h-[60px] w-[200px] "
                    src={logo}
                    alt="logo"
                  />
                </div>
                <p className="text-xs font-normal text-[#4D5761]">
                  As Uzmoon, we are a dynamic company specialized in software, marketing, and design. As a passionate team, we take pride in providing our customers with tailor-made solutions that perfectly align with their goals and objectives.
                </p>
              </div>
            </div>
            <div className="col-span-4 flex flex-row gap-x-8 md:col-span-2">
              <div className="flex md:w-1/2 ">
                <div className="flex flex-col ">
                  <div className="my-2 font-semibold">Address</div>
                  <div className="font-normal text-[#6B7280]">Türkiye, İstanbul</div>
                  <div className="font-normal text-[#6B7280]">Kosovo, Prizren</div>
                  <div className="font-normal text-[#6B7280]">USA, New York</div>
                </div>
              </div>
              <div className="md:w-1/2">
                <p className="my-2 font-semibold">Contact</p>
                <p className="font-normal text-[#6B7280]">
                  <Link to="mailto:info@uzmoon.com" target="_blank">
                    info@uzmoon.com
                  </Link>
                  <div className="flex my-5">
                    <Link to="https://www.instagram.com/uzmooncom/" target="_blank">
                      <AiOutlineInstagram className="text-3xl" />
                    </Link>
                    <Link to="https://github.com/uzmooncom" target="_blank">
                      <AiFillGithub className="text-3xl" />
                    </Link>
                    <Link to="https://www.linkedin.com/company/uzmoon" target="_blank">
                      <AiFillLinkedin className="text-3xl" />
                    </Link>
                    <Link to="mailto:info@uzmoon.com" target="_blank">
                      <AiOutlineMail className="text-3xl" />
                    </Link>
                    <Link to="https://twitter.com/uzmooncom" target="_blank">
                      <AiOutlineTwitter className="text-3xl" />
                    </Link>
                  </div>

                </p>
              </div>
            </div>
          </div>
          <hr className="col-span-2"></hr>
          <div className="grid grid-cols-2 items-center justify-between gap-y-3 py-8 text-sm">
            <div className="col-span-2 text-[#4D5761] md:col-span-1">
              ©Uzmoon
            </div>
            <div className="col-span-2 flex flex-row justify-end gap-x-6 text-main-color md:col-span-1">
              <Link to='/logos'>Logos</Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
              <Link to="/terms-of-service">Terms of Service</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
