
export const codeSnippets = [
    {
        language: "JavaScript",
        language_code: 'javascript',
        code: `console.log("Hello, World!");`,
        extension: "js",
    },
    {
        language: "C",
        language_code: 'c',
        extension: "c",
        code: `#include <stdio.h>
int main() {
    printf("Hello, World!");
    return 0;
}`
    },
    {
        language: "C++",
        language_code: 'cpp',

        code: `#include <iostream>
int main() {
    std::cout << "Hello, World!";
    return 0;
}`
    },
    {
        language: "Python",
        language_code: 'python',
        code: `print("Hello, World!")`
    },
    {
        language: "Php",
        language_code: 'php',
        code: `<?php 
  echo "Hello, World!";
?>`
    },
    {
        language: "Java",
        language_code: 'java',
        code: `public class Main {
    public static void main(String[] args) {
        System.out.println("Hello, World!");
    }
}`,

    },
    {
        language: "Ruby",
        language_code: 'ruby',
        code: `puts "Hello, World!"`
    },
    {
        language: "Swift",
        language_code: 'swift',
        code: `print("Hello, World!")`
    },
    {
        language: "Go",
        language_code: 'go',
        code: `package main 
import "fmt"
func main() {
    fmt.Println("Hello, World!")
}`
    },
    {
        language: "Scala",
        language_code: 'scala',
        code: `object Main extends App {
    println("Hello, World!")
}`
    },
    {
        language: "Matlab",
        language_code: 'matlab',
        code: `disp("Hello, World!")`

    },
    {
        language: "Delphi",
        language_code: 'delphi',
        code: `program HelloWorld;
begin
  WriteLn('Hello, World!');
end.`
    },
    {
        language: "Dart",
        language_code: 'dart',
        code: `void main() {
    print("Hello, World!");
}`
    },
    {
        language: "Kotlin",
        language_code: 'kotlin',
        code: `fun main() {
    println("Hello, World!")
}`
    },
    {
        language: "Rust",
        language_code: 'rust',
        code: `fn main() {
    println!("Hello, World!");
}`
    },
    {
        language: "Perl",
        language_code: 'perl',
        code: `print "Hello, World!\n";`
    },
    {
        language: "Haskell",
        language_code: 'haskell',
        code: `main = putStrLn "Hello, World!"`
    },
    {
        language: "Pascal",
        language_code: 'pascal',
        code: `program HelloWorld;
begin
  WriteLn('Hello, World!');
end.`
    },
    {
        language: "R",
        language_code: 'r',
        code: `print("Hello, World!")`
    },
    {
        language: "Shell",
        language_code: 'shell',
        code: `echo "Hello, World!"`
    },
    {
        language: "SQL",
        language_code: 'sql',
        code: `SELECT 'Hello, World!';`
    },
    {
        language: "TypeScript",
        language_code: 'typescript',
        code: `console.log("Hello, World!");`
    },
    {
        language: "Lua",
        language_code: 'lua',
        code: `print("Hello, World!")`
    },
    {
        language: "Objective-C",
        language_code: 'objectivec',
        code: `#import <Foundation/Foundation.h>
int main() {
    NSLog(@"Hello, World!");
    return 0;
}`
    },
    {
        language: "Visual Basic",
        language_code: 'visualbasic',
        code: `Module HelloWorld
    Sub Main()
        Console.WriteLine("Hello, World!")
    End Sub
End Module`
    },
    {
        language: "PowerShell",
        language_code: 'powershell',
        code: `Write-Output "Hello, World!"`
    },
    {
        language: "Groovy",
        language_code: 'groovy',
        code: `println "Hello, World!"`
    },
    {
        language: "Elixir",
        language_code: 'elixir',
        code: `IO.puts "Hello, World!"`
    },
    {
        language: "Clojure",
        language_code: 'clojure',
        code: `(println "Hello, World!")`
    },
    {
        language: "C#",
        language_code: 'csharp',
        code: `using System;
class Program {
    static void Main() {
        Console.WriteLine("Hello, World!");
    }
}`
    },
    {
        language: "F#",
        language_code: 'fsharp',
        code: `printfn "Hello, World!"`
    },
    {
        language: "Erlang",
        language_code: 'erlang',
        code: `main(_) -> io:fwrite("Hello, World!\n").`
    },
    {
        language: "Ada",
        language_code: 'ada',
        code: `with Ada.Text_IO; use Ada.Text_IO;
procedure HelloWorld is
begin
    Put_Line("Hello, World!");
end HelloWorld;`
    },
    {
        language: "Fortran",
        language_code: 'fortran',
        code: `program HelloWorld
    print *, "Hello, World!"
end program HelloWorld`
    },
    {
        language: "ABAP",
        language_code: 'abap',
        code: `REPORT ZHELLO_WORLD.
WRITE: 'Hello, World!'.`
    },
    {
        language: "Apex",
        language_code: 'apex',
        code: `public class HelloWorld {
    public static void main(String[] args) {
        System.debug('Hello, World!');
    }
}`
    },
    {
        language: "PL/SQL",
        language_code: 'plsql',
        code: `BEGIN
    DBMS_OUTPUT.PUT_LINE('Hello, World!');
END;`
    },
    {
        language: "Zsh",
        language_code: 'zsh',
        code: `echo "Hello, World!"`
    },
    {
        language: "Visual Basic .NET",
        language_code: 'vbnet',
        code: `Module HelloWorld
    Sub Main()
        Console.WriteLine("Hello, World!")
    End Sub
End Module`
    },
    {
        language: "Eiffel",
        language_code: 'eiffel',
        code: `class
    HELLO_WORLD
create
    make
feature
    make
        do
            print ("Hello, World!")
        end
end`
    },
    {
        language: "HTML",
        language_code: 'html',
        code: `<!DOCTYPE html>
<html>
<head>
    <title>Hello, World!</title>
</head>
<body>
    <h1>Hello, World!</h1>
</body>
</html>`
    },
    {
        language: "COBOL",
        language_code: 'cobol',
        code: `IDENTIFICATION DIVISION.
PROGRAM-ID. HelloWorld.
PROCEDURE DIVISION.
    DISPLAY 'Hello, World!'.
    STOP RUN.`
    },
    {
        language: "D",
        language_code: 'd',
        code: `import std.stdio;
void main() {
    writeln("Hello, World!");
}`
    },
    {
        language: "Dockerfile",
        language_code: 'dockerfile',
        code: `FROM alpine
CMD ["echo", "Hello, World!"]`
    },
    {
        language: "Forth",
        language_code: 'forth',
        code: `." Hello, World!" CR`
    },
    {
        language: "FoxPro",
        language_code: 'foxpro',
        code: `? "Hello, World!"`
    },
    {
        language: "Haxe",
        language_code: 'haxe',
        code: `class Main {
    static function main() {
        trace("Hello, World!");
    }
}`
    },
    {
        language: "HCL",
        language_code: 'hcl',
        code: `output "Hello, World!" { }`
    },
    {
        language: "JSON",
        language_code: 'json',
        code: `{
    "message": "Hello, World!"
}`
    },
    {
        language: "Julia",
        language_code: 'julia',
        code: `println("Hello, World!")`
    },
    {
        language: "Lisp",
        language_code: 'lisp',
        code: `(print "Hello, World!")`
    },
    {
        language: "Makefile",
        language_code: 'makefile',
        code: `all:
    @echo "Hello, World!"`
    },
    {
        language: "Markdown",
        language_code: 'markdown',
        code: `# Hello, World!`
    },
    {
        language: "Nim",
        language_code: 'nim',
        code: `echo "Hello, World!"`
    },
    {
        language: "OCaml",
        language_code: 'ocaml',
        code: `print_endline "Hello, World!"`
    },
    {
        language: "Puppet",
        language_code: 'puppet',
        code: `notify { 'Hello, World!': }`
    },
    {
        language: "Q#",
        language_code: 'qsharp',
        code: `namespace HelloWorld {
    open Microsoft.Quantum.Intrinsic;
    operation SayHello() : Unit {
        Message("Hello, World!");
    }
}`
    },
    {
        language: "SAS",
        language_code: 'sas',
        code: `data _null_;
    put 'Hello, World!';
run;`
    },
    {
        language: "Sass",
        language_code: 'sass',
        code: `@mixin hello-world {
    content: "Hello, World!";
}`
    },
    {
        language: "SCSS",
        language_code: 'scss',
        code: `@mixin hello-world {
    content: "Hello, World!";
}`
    },
    {
        language: "SQLPL",
        language_code: 'sqlpl',
        code: `BEGIN
    DECLARE msg CHAR(13) DEFAULT 'Hello, World!';
    SELECT msg INTO msg FROM sysibm.sysdummy1;
    DBMS_OUTPUT.PUT_LINE(msg);
END;`
    },
    {
        language: "Tcl",
        language_code: 'tcl',
        code: `puts "Hello, World!"`
    },
    {
        language: "TeX",
        language_code: 'tex',
        code: `Hello, World!`
    },

    {
        language: "Verilog",
        language_code: 'verilog',
        code: `module HelloWorld;
initial
    begin
        $display("Hello, World!");
        $finish;
    end
endmodule`
    },
    {
        language: "Vim script",
        language_code: 'vim',
        code: `echo "Hello, World!"`
    },
    {
        language: "XML",
        language_code: 'xml',
        code: `<?xml version="1.0" encoding="UTF-8"?>
<root>
    <message>Hello, World!</message>
</root>`
    },
    {
        language: "YAML",
        language_code: 'yaml',
        code: `message: Hello, World!`
    },
    {
        language: "Zig",
        language_code: 'zig',
        code: `const std = @import("std");
pub fn main() void {
    std.debug.print("Hello, World!\n");
}`
    },
    {
        language: "Assembly",
        language_code: "assembly",
        code: `    global _main
      extern _printf
  
      section .text
  _main:
      push    message
      call    _printf
      add        esp, 4
  message:
      db    'Hello World', 10, 0`
    },
    {
        language: "VHDL",
        language_code: 'vhdl',
        code: `library ieee;
use ieee.std_logic_1164.all;
use ieee.std_logic_arith.all;
use ieee.std_logic_unsigned.all;
entity HelloWorld is
end HelloWorld;
architecture rtl of HelloWorld is
begin
    process
    begin
        report "Hello, World!";
        wait;
    end process;
end rtl;`
    },
];