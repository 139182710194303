import React, { useState } from "react";
import { Alert } from "@material-tailwind/react";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    budget: "",
  });

  const budgets = [
    "Less than $5,000",
    "$5,000 - $10,000",
    "$10,000 - $20,000",
    "$20,000 - $30,000",
    "$30,000 - $50,000",
    "$50,000 - $100,000",
    "$100,000 + ",
  ];
  const [alert, setAlert] = useState({
    type: "", // success, error, warning gibi
    message: "",
    isOpen: false,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://uzmoon.com/api/mail.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      setFormData({
        name: "",
        email: "",
        message: "",
        budget: "",
      });

      const data = await response.json();
      showSuccessAlert(data.message);
    } catch (error) {
      showErrorAlert("An error occurred while sending the email");
    }

  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const showSuccessAlert = (message) => {
    setAlert({
      type: "success",
      message: message,
      isOpen: true,
    });
  };

  const showErrorAlert = (message) => {
    setAlert({
      type: "error",
      message: message,
      isOpen: true,
    });
  };

  const closeAlert = () => {
    setAlert({
      type: "",
      message: "",
      isOpen: false,
    });
  };

  return (
    <div className="flex flex-col items-center justify-center pb-10" id="contact">
      <div className="flex items-center justify-center pt-32">
        <div className="title">
          <div>Contact</div>
          <div className="h-1 w-48 mb-6" style={{ background: 'black' }}></div>
        </div>
      </div>
      <form onSubmit={handleSubmit} className="w-full sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/3 px-4 py-4">
        <div className="form-group mb-4">
          <label>Name:</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className="border p-2 w-full ae-input"
          />
        </div>
        <div className="form-group mb-4">
          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="border p-2 w-full ae-input"
          />
        </div>
        <div className="form-group mb-4">
          <label>Message:</label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            className="border p-2 w-full ae-input"
          />
        </div>
        <div className="form-group mb-4">
          <label>Budget:</label>
          <select
            name="budget"
            value={formData.budget}
            onChange={handleChange}
            required
            className="border p-2 w-full ae-input"
          >
            <option value="">Select Budget</option>
            {budgets.map((budget, index) => (
              <option key={index} value={budget}>
                {budget}
              </option>
            ))}
          </select>

          <Alert
            color={alert.type === "success" ? "green" : "orange"}
            open={alert.isOpen}
            onClose={closeAlert}
            className="my-4 flex items-center justify-between"
            animate={{
              mount: { y: 0 },
              unmount: { y: 100 },
            }}
          >
            {alert.message}
          </Alert>
        </div>
        <button type="submit" className="uzmoon-btn btn">Submit</button>
      </form>

    </div>


  );
};

export default Contact;
