import { useEffect } from "react";
import bg from "../../assets/images/bg.jpg";
const Mka = () => {
  useEffect(() => {
    const squares = document.querySelectorAll(".square");
    const fadeOutClasses = ["fade-out-0", "fade-out-1", "fade-out-2", "fade-out-3", "fade-out-4", "fade-out-5", "fade-out-6", "fade-out-7", "fade-out-8"];

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          squares.forEach((square, index) => {
            square.classList.add("fade", fadeOutClasses[index]);
          });
        } else {
          squares.forEach((square, index) => {
            square.classList.remove("fade", fadeOutClasses[index]);
          });
        }
      });
    });

    observer.observe(squares[0]);

    return () => {
      observer.disconnect();
    };
  }, []);
  // Nations that turn their backs on new technology fall behind in the race of humanity and are destined to perish
  return (
    <div className="py-10 flex items-center justify-center " style={{
      backgroundImage: `url(${bg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      height: '100%',
      width: '100%',
      position: 'relative',
      overflow: 'hidden',
      zIndex: '-1',

    }}>
      <div className="mx-auto">
        <div className="text-xl md:text-[40px] text-white font-bold py-8">
          <article className="text-center space-y-3 md:space-y-10">
            <div className="">
              <span className="square ">"Nations that </span>
              <span className="square ">turn their backs </span>
            </div>
            <div className="">
              <span className="square ">on new technology </span>
              <span className="square ">fall behind in the race </span>
            </div>
            <div className="">
              <span className="square ">of humanity and are </span>
              <span className="square ">destined to perish"</span>
            </div>
            <div className="text-right">
              <span className="square ">- Mustafa  </span>
              <span className="square ">Kemal </span>
              <span className="square ">Atatürk </span>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default Mka;
