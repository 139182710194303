export const services = [  {
    name: ["Software", "Development"],
    image: '/assets/images/services/1.jpg',
    services: [
      "Web development",
      "Mobile development",
      "Desktop development",
      "Cloud development",
      "DevOps",
      "API development",
    ],
  },
  {
    name: ["Design", "Services"],
    image : '/assets/images/services/2.jpg',
    services: [
        "UI/UX design",
        "Branding",
        "Logo design",
        "Graphic design",
        "Product design",
        "3D design",
    ],
  },
  {
    name: ["Marketing", "Services"],
    image : '/assets/images/services/3.jpg',
    services: [
        "Digital marketing",
        "Social media marketing",
        "Content marketing",
        "Email marketing",
        "Search engine optimization",
        "Search engine marketing",
    ],
  },
];
