import React from "react";
import Nova from "./nova";
// import { Link } from "react-router-dom";
import logo from "../../assets/images/UZMOON_LOGO.svg";
const Hero = () => {
  return (
    <div className="container">
      <div>
        <Nova />
      </div>
      <div
        className="hero flex h-screen flex-col justify-center lg:mr-44"
        data-aos="zoom-in"
        data-aos-delay="200"
      >
        <div className="">
          <div className="hero-title font-['SpaceGrotesk']  font-bold text-main-color">
            <img src={logo} alt="Uzmoon" className="h-50 w-50" height={40} width={500} />
            <div className="md:text-3xl text-xl"> We came to update the world </div>
          </div>
          <p className="mt-[24px] w-2/3 max-w-[380px] font-['Inter'] text-base md:text-lg font-semibold md:font-normal text-main-color">
            Chasing the Yet to Be!
          </p>
          <div className="flex gap-x-3 pt-5  font-['Inter']">
            <a href="#contact">
              <input
                type="button"
                className="btn uzmoon-btn"
                value="Let's talk"
              />
            </a>
            <a href="#services">
              <input
                type="button"
                className="btn border-btn normal-btn"
                value="Our services"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
